import React from 'react'
import styled from 'styled-components'

import { HelmetFunc } from 'components/PageMetaData'

import { VideoBlock } from 'blocks/Video'
import i18n from 'i18n/config'
import { backgroundColors, colors } from 'styles/colors'
import { headerBg } from 'styles/headerBg'
import { mobileAfterBorder } from 'styles/mobileAfterBorder'
import { Container } from 'components/Container'
import { useStaticQuery, graphql } from 'gatsby'
import { getDataByLanguage } from 'utils/getDataByLanguage'
import { Layout } from 'layout/Layout'
import { Title } from 'components/TitleComponent'
import { displayWidth } from 'styles/width'
import { IconListNumber } from 'components/IconListQuestion'

const pageMetadata = {
    uk: {
        title: 'Як самостійно обміряти квартиру для дизайну та ремонту',
        description:
            'Дізнайтесь, як обміряти квартиру для дизайну та ремонту. Детальний покроковий посібник з відео допоможе вам своїми руками обміряти квартиру, будинок, комерційне приміщення без помилок.',
    },
    ru: {
        title: ' Как самостоятельно обмерить квартиру для дизайна и ремонта',
        description:
            'Узнайте, как обмерить квартиру для дизайна и ремонта. Подробное пошаговое руководство с видео поможет вам своими руками обмерить квартиру, дом, коммерческое помещение без ошибок.',
    },
    en: {
        title:
            'How to independently measure an apartment for design and renovation',
        description:
            'Find out how to measure an apartment for design and renovation. A detailed step-by-step guide with a video will help you measure your apartment, house, or commercial space with your own hands without mistakes.',
    },
}

const MeasureWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    background-color: ${backgroundColors.project};
    position: relative;
    border-bottom: 1px solid ${colors.dark};
    ${mobileAfterBorder};
    :before {
        ${headerBg}
    }
`

const TitleS = styled(Title)`
    max-width: calc(100% - 48px);
`
const MeasureInstructionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin: 10px 24px;
    p {
        font-size: 20px;
    }
    li {
        margin: 8px 8px 8px 24px;
    }
    @media (min-width: ${displayWidth.tablet}) {
        margin: 20px 48px;
    }
`
const InstructionText = styled.div`
    margin: 32px;
    ul {
        padding-left: 15px;
    }
    li {
        margin: 20px;
        list-style: none;
        display: flex;
        align-items: flex-start;
    }
    strong {
        font-weight: bold;
    }
    p {
        display: inline-block;
        color: #000;
        font-size: 16px;
    }
    h1 {
        font-family: 'Yeseva One', sans-serif;
        display: inline-block;
        color: #000;
        font-size: 30px;
        font-weight: 700;
        margin: 10px 30px 30px 0;
        padding-left: 10px;
        letter-spacing: 1.7px;
        border-left: 8px solid #5f7db5;
    }
    .circle {
        display: flex;
        align-items: center;
        font-size: 50px;
        color: #5f7db5;
        height: 20px;
        width: 15px;
        margin-right: 7px;
    }
`

const MeasureInstruction = () => {
    const data = useStaticQuery(graphql`
        query {
            allMeasureInstructionYaml {
                edges {
                    node {
                        title
                        videoTitle
                        videoDescription
                        titleText
                        items {
                            question
                        }
                        parent {
                            ... on File {
                                name
                            }
                        }
                    }
                }
            }
        }
    `)

    const measureYaml = getDataByLanguage(
        data.allMeasureInstructionYaml,
        i18n.language
    )

    return (
        <Layout>
            <HelmetFunc data={pageMetadata} />
            <MeasureWrapper>
                <Container columns={'1fr'} tabletColumns={'1fr'}>
                    <InstructionText
                        dangerouslySetInnerHTML={{
                            __html: measureYaml.title,
                        }}
                    />
                </Container>
                {i18n.language === 'ru' && (
                    <VideoBlock
                        color={backgroundColors.project}
                        title={measureYaml.videoTitle}
                        description={measureYaml.videoDescription}
                        url={'https://www.youtube.com/watch?v=35bj3Gi6nDM'}
                    />
                )}
                {i18n.language === 'uk' && (
                    <VideoBlock
                        color={backgroundColors.project}
                        title={measureYaml.videoTitle}
                        description={measureYaml.videoDescription}
                        url={' https://youtu.be/VwzZ4QtBG3E'}
                    />
                )}
                <Container columns={'1fr'} tabletColumns={'1fr'}>
                    <TitleS> {measureYaml.titleText} </TitleS>
                    <MeasureInstructionWrapper>
                        <IconListNumber
                            items={measureYaml.items}
                            fill={colors.accentText}
                            titleSize={28}
                            background={backgroundColors.project}
                        />
                    </MeasureInstructionWrapper>
                </Container>
            </MeasureWrapper>
        </Layout>
    )
}

export default MeasureInstruction
